<template>
    <b-card title="Store Information">
        <b-form>
            <store-information :isEdit="isEdit" :storeInfo="storeInfo" :getStoreInformation="getStoreInformation" />
            <hr>
            <b-row>
                <b-col md="12">
                    <!-- table -->
                    <vue-good-table :line-numbers=true :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                        enabled: true,
                        externalQuery: searchTerm
                    }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                        <template slot="table-row" slot-scope="props">

                            <!-- Column: Currency -->
                            <span v-if="props.column.field === 'currency'">
                                <span>
                                    {{ props.row.currency.name }}
                                </span>
                            </span>

                            <!-- Column: Weekend Day -->
                            <span v-else-if="props.column.field === 'weeklyOffDay'">
                                <span v-if="props.row.weeklyOffDay==0">
                                </span>
                                <span v-else-if="props.row.weeklyOffDay==1">
                                    Sunday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay==2">
                                    Monday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay==3">
                                    Tuesday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay==4">
                                    Wednesday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay==5">
                                    Thursday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay==6">
                                    Friday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay==7">
                                    Saturday
                                </span>
                            </span>

                            <!-- Column: Action -->
                            <span v-else-if="props.column.field === 'action'">
                                <span>
                                    <b-button @click="editButton(props.row.id)" variant="flat-primary"><feather-icon icon="EditIcon" class="text-body align-middle mr-25" /></b-button>
                                    <b-button @click="deleteStore(props.row.id)" variant="flat-primary"><feather-icon icon="TrashIcon" class="text-body align-middle mr-25" /></b-button>
                                </span>
                            </span>

                            <!-- Column: Common -->
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                        <!-- pagination -->
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        Showing 1 to
                                    </span>
                                    <b-form-select v-model="pageLength" :options="['5','10', '20', '50','100']" class="mx-1"
                                        @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                                </div>
                                <div>
                                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                        first-number last-number align="right" prev-class="prev-item"
                                        next-class="next-item" class="mt-1 mb-0"
                                        @input="(value) => props.pageChanged({ currentPage: value })">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BForm, BRow,BFormSelect, BCol, BFormGroup, BButton, BButtonGroup, BFormInput, BPagination, BDropdown, BDropdownItem
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import StoreInformation from '../admin/setup/StoreInformation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'

export default {
    components: {
        VueGoodTable, vSelect,BFormSelect, BCard, BForm, BRow, BCol, BFormGroup, BPagination, BDropdown, BDropdownItem,
        BButton, BButtonGroup, BFormInput, flatPickr, StoreInformation
    },
    data() {
        return {
            pageLength: 5,
            dir: false,
            columns: [

                {
                    label: 'Store Name',
                    field: 'storeName',
                },
                {
                    label: 'Business Name',
                    field: 'businessName',
                },
                {
                    label: 'Address',
                    field: 'address',
                },
                {
                    label: 'Location',
                    field: 'location',
                },
                {
                    label: 'Mobile No',
                    field: 'mobileNo',
                },
                {
                    label: 'Phone No',
                    field: 'phoneNo',
                },
                {
                    label: 'Opening Time',
                    field: 'openingTime',
                },
                {
                    label: 'Closing Time',
                    field: 'closingTime',
                },
                {
                    label: 'Action',
                    field: 'action',
                },


            ],
            rows: [],
            searchTerm: '',
            options: [],
            isEdit: false,
            storeInfo: {},
            weekDaysOption: [
                { 'id': 1, 'name': 'Sunday' },
                { 'id': 2, 'name': 'Monday' },
                { 'id': 3, 'name': 'Tuesday' },
                { 'id': 4, 'name': 'Wednesday' },
                { 'id': 5, 'name': 'Thursday' },
                { 'id': 6, 'name': 'Friday' },
                { 'id': 7, 'name': 'Saturday' },
            ],
        }
    },
    methods: {
        getStoreInformation() {
            this.id = 0;
            this.addUpdateButton = "Add";
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        deleteStore(id) {
            let self = this;
            this.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/masters/stores/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getStoreInformation();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },
        editButton(id) {
            let self = this;
            self.isEdit = true;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores/' + id, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeInfo=response.data;
                    const object = self.weekDaysOption.find(obj => obj.id == response.data.weeklyOffDay);
                    self.storeInfo.weekOffDay={'id':object.id,'name':object.name};

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    created() {
        this.getStoreInformation();
    },
}
</script>

<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>

