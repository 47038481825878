<template>
    <b-card center title="Store Information">
        <!-- form -->
        <b-form>
            <b-row>
                <!-- Store Name -->
                <b-col md="6">
                    <b-form-group label="Store Name">
                        <b-form-input v-model="storeInfo.storeName" placeholder="Please Enter Store Name" />
                    </b-form-group>
                </b-col>

                <!-- Business Name -->
                <b-col md="6">
                    <b-form-group label="Business Name">
                        <b-form-input v-model="storeInfo.businessName" placeholder="Please Enter Business Name" />
                    </b-form-group>
                </b-col>

                <!-- Store Address -->
                <b-col md="6">
                    <b-form-group label="Store Address">
                        <b-form-input v-model="storeInfo.address" placeholder="Please Enter Store Address" />
                    </b-form-group>
                </b-col>

                <!-- Store Location -->
                <b-col md="6">
                    <b-form-group label="Store Location">
                        <b-form-input v-model="storeInfo.location" placeholder="Please Enter Store Location" />
                    </b-form-group>
                </b-col>
                <!-- Store State -->
                <b-col md="6">
                    <b-form-group label="State">
                        <b-form-input v-model="storeInfo.state" placeholder="Please Enter State" />
                    </b-form-group>
                </b-col>
                <!-- Store City -->
                <b-col md="6">
                    <b-form-group label="City">
                        <b-form-input v-model="storeInfo.city" placeholder="Please Enter City" />
                    </b-form-group>
                </b-col>

                <!-- mobile -->
                <b-col md="6">
                    <b-form-group label="Mobile No">
                        <b-form-input v-model="storeInfo.mobileNo" placeholder="Please Enter Mobile no" />
                    </b-form-group>
                </b-col>

                <!-- phone -->
                <b-col md="6">
                    <b-form-group label="Phone No">
                        <b-form-input v-model="storeInfo.phoneNo" placeholder="Please Enter Phone No" />
                    </b-form-group>
                </b-col>

                <!-- email -->
                <b-col md="6">
                    <b-form-group label="Email Id">
                        <b-form-input v-model="storeInfo.emailId" placeholder="Please Enter Email Id" />
                    </b-form-group>
                </b-col>

                <!-- Currency -->
                <b-col md="6">
                    <b-form-group label="Currency">
                        <v-select v-model="storeInfo.currency" placeholder="Select Currency"
                            :options="currencyOptions"></v-select>
                    </b-form-group>
                </b-col>

                <!-- Enable Operation Time -->
                <b-col md="6" class="mt-3">
                    <b-form-checkbox checked="false" v-model="storeInfo.enabelOperationTime">Enable Operation Time
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <!-- <b-row class="border"> -->
            <b-row class="border" v-if="storeInfo.enabelOperationTime">
                <!-- Opening Time -->
                <b-col md="3" class="mt-1">
                    <b-form-group label="Opening Time">
                        <b-time v-model="storeInfo.openingTime"></b-time>
                    </b-form-group>
                </b-col>

                <!-- Closing Time -->
                <b-col md="3" class="mt-1">
                    <b-form-group label="Closing Time">
                        <b-time v-model="storeInfo.closingTime"></b-time>
                    </b-form-group>
                </b-col>

                <!-- Week Off Days -->
                <b-col md="6" class="mt-1">
                    <b-form-group label="Weekly Off Day">
                        <v-select v-model="storeInfo.weekOffDay" label="name" placeholder="Select Week Off Day"
                            :options="weekDaysOption"></v-select>
                    </b-form-group>
                    <b-form-group label="Select Default Time Zone">
                        <v-select v-model="storeInfo.defaultTimeZone" :options="timeZoneOption" />
                        <!-- {{ new Date().toLocaleString() }} -->
                    </b-form-group>
                </b-col>

            </b-row>
            <hr>
            <b-row>
                <b-col>
                    <b-form-group>
                        <b-form-checkbox v-model="storeInfo.applyDiscountRestrication">Apply Discount
                            Restriction</b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="border" v-if="storeInfo.applyDiscountRestrication">
                <b-col md="6" class="mt-1">
                    <b-form-group label="Max Discount Restrication Flat">
                        <b-form-input v-model="storeInfo.maxDiscountRestricationFlat"
                            placeholder="Max Discount Restrication Flat"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="mt-1">
                    <b-form-group label="Max Discount Restrication Percentage">
                        <b-form-input v-model="storeInfo.maxDiscountRestricationPercentage"
                            placeholder="Max Discount Restrication Percentage"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-center">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1"
                        @click="addUpdateStoreInformation">
                        {{ isEdit? 'Update': 'Add' }}
                    </b-button>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" class="mt-1"
                        variant="outline-secondary" @click="resetForm">
                        Reset
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BFormFile,
    BMedia, BMediaAside, BMediaBody, BLink, BImg, BAvatar, BFormCheckbox, BTime
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'
import util from '@/store/utils'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
    components: {
        BButton, BTime,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BFormTextarea,
        BFormFile,
        BMedia, BMediaAside, BMediaBody, BLink, BImg, BAvatar, BFormCheckbox,
        vSelect,
        flatPickr,
        Cleave,
    },
    directives: {
        Ripple,
    },
    props: {
        isEdit: Boolean,
        storeInfo: Array,
        getStoreInformation: Function
    },

    data() {
        return {
            id: 0,
            addUpdateButton: 'Add',
            storeName: '',
            businessName: '',
            address: '',
            location: '',
            state: '',
            city: '',
            mobileNo: '',
            phoneNo: '',
            emailId: '',
            enabelOperationTime: false,
            openingTime: '',
            closingTime: '',
            // weekDaysOption: util.weekDaysOptions(),
            weekDaysOption:[],
            weekOffDay: {'id': 0, 'name':'---Select---'},
            // timeZoneOption: util.timeZoneOptions(),
            timeZoneOption:[],
            defaultTimeZone: '',
            currencyOptions: [],
            // currencyOptions: util.currencyOptions(),
            applyDiscountRestriction: false,
            maxDiscountRestricationPercentage: 0,
            maxDiscountRestricationFlat: 0,

        }
    },
    methods: {
        getCurrencyType() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getCurrencyes', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.currencyOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        getWeekend() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getWeekDayList', data))
                .then(function (response) {
                    let i=0;
                    for (let index = 0; index < response.data.length; index++) {
                        i++;
                        self.weekDaysOption.push({'id':i,'name':response.data[index]})
                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        getTimeZoneList(){
            
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getAllTimezonesList', data))
                .then(function (response) {
                    self.timeZoneOption=response.data;
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        getStoreInfo(id) {
            this.addUpdateButton = "Add";
            let self = this;

            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/masters/stores/' + id, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeInfo = response.data;

                    if (response.data.weeklyOffDay > 0) {
                        const object = self.weekDaysOption.find(obj => obj.id == response.data.weeklyOffDay);
                        self.storeInfo.weekOffDay = { 'id': object.id, 'name': object.name };
                    } else {
                        self.storeInfo.weekOffDay = { 'id': 0, 'name': '---Select---' };

                    }


                    if (response.data.currency == null) {
                        self.storeInfo.currency = '';
                    } else {
                        const object1 = self.currencyOptions.find(obj => obj == response.data.currency);
                        self.storeInfo.currency = object1;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        addUpdateStoreInformation() {
            let self = this;
            if ((self.storeInfo.storeName != '') && (self.storeInfo.currency != '')) {
                if (self.isEdit == false) {
                    var axios = require('axios');
                    var data = JSON.stringify({
                        "storeName": self.storeInfo.storeName,
                        "businessName": self.storeInfo.businessName,
                        "address": self.storeInfo.address,
                        "location": self.storeInfo.location,
                        "state": self.storeInfo.state,
                        "city": self.storeInfo.city,
                        "mobileNo": self.storeInfo.mobileNo,
                        "phoneNo": self.storeInfo.phoneNo,
                        "emailId": self.storeInfo.emailId,
                        "enabelOperationTime": self.storeInfo.enabelOperationTime,
                        "openingTime": self.storeInfo.openingTime,
                        "closingTime": self.storeInfo.closingTime,
                        "weeklyOffDay": self.storeInfo.weekOffDay.id,
                        "currency": self.storeInfo.currency,
                        "defaultTimeZone": self.storeInfo.defaultTimeZone,
                        "applyDiscountRestrication": self.storeInfo.applyDiscountRestrication,
                        "maxDiscountRestricationFlat": self.storeInfo.maxDiscountRestricationFlat,
                        "maxDiscountRestricationPercentage": self.storeInfo.maxDiscountRestricationPercentage
                    });
                    axios(api.getApi('Post', '/masters/stores', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Store Information Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getStoreInformation();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    let self = this;
                    var axios = require('axios');
                    var data = JSON.stringify({
                        "storeName": self.storeInfo.storeName,
                        "businessName": self.storeInfo.businessName,
                        "address": self.storeInfo.address,
                        "location": self.storeInfo.location,
                        "state": self.storeInfo.state,
                        "city": self.storeInfo.city,
                        "mobileNo": self.storeInfo.mobileNo,
                        "phoneNo": self.storeInfo.phoneNo,
                        "emailId": self.storeInfo.emailId,
                        "enabelOperationTime": self.storeInfo.enabelOperationTime,
                        "openingTime": self.storeInfo.openingTime,
                        "closingTime": self.storeInfo.closingTime,
                        "weeklyOffDay": self.storeInfo.weekOffDay.id,
                        "currency": self.storeInfo.currency,
                        "defaultTimeZone": self.storeInfo.defaultTimeZone,
                        "applyDiscountRestrication": self.storeInfo.applyDiscountRestrication,
                        "maxDiscountRestricationFlat": self.storeInfo.maxDiscountRestricationFlat,
                        "maxDiscountRestricationPercentage": self.storeInfo.maxDiscountRestricationPercentage
                    });
                    axios(api.getApi('put', '/masters/stores/' + self.storeInfo.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));

                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Store Information Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getStoreInformation();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Store Name And Currency .",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }


        },

        resetForm() {
            this.storeName = ''
            this.businessName = '',
                this.address = '',
                this.location = '',
                this.state = '',
                this.city = '',
                this.mobile = '',
                this.phone = '',
                this.email = '',
                this.enableOpertionTime = false,
                this.openingTime = '',
                this.closingTime = '',
                this.weekOffDay = '',
                this.timeZone = ''
        },

    },
    created() {

        let self = this;
        self.getCurrencyType();
        self.getWeekend();
        self.getTimeZoneList();
        if (self.isEdit) {
            self.getStoreInfo(self.$store.state.storeId)

        } else {
            self.storeInfo.currency = util.currency();
            self.storeInfo.weekOffDay = util.weekOffDay();
            
            self.storeInfo.maxDiscountRestricationFlat=0;
            self.storeInfo.maxDiscountRestricationPercentage=0;
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
